<template>
  <section class="audit-list">
    <el-table :data="tableData" border style="width: 100%" v-loading="loading">
      <el-table-column  :resizable="false" prop="name" label="事件名称" align="center" width="180">
      </el-table-column>
      <el-table-column  :resizable="false" prop="keyword" label="关键词" align="center" width="180">
      </el-table-column>
      <el-table-column  :resizable="false" prop="rejectMessage" label="反馈消息" align="center" width="180">
      </el-table-column>
      <el-table-column  :resizable="false" prop="createDate" label="创建时间" align="center" width="100">
        <template scope="{ row }">
          {{ dateFormat(row.createDate) }}
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" prop="startDate" align="center" label="起始时间" width="100">
        <template scope="{ row }">
          {{ dateFormat(row.startDate) }}
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" prop="endDate" align="center" label="结束时间" width="100">
        <template scope="{ row }">
          {{ dateFormat(row.endDate) }}
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" prop="approvalStatus" align="center" label="状态">
        <template scope="{ row }">
         <span v-if="row.approvalStatus == 0" style="color: #CCCCCC;">未审核</span>
         <span v-else-if="row.approvalStatus == 1" style="color: #2E8B57;">已审核</span>
        </template>
      </el-table-column>

      <el-table-column  :resizable="false" prop="whetherToPpass" align="center" label="是否通过">
        <template scope="{ row }">
          <span v-if="row.whetherToPpass == 0" style="color: #CCCCCC;">否</span>
          <span v-if="row.whetherToPpass == null" style="color: #CCCCCC;">否</span>
          <span v-else-if="row.whetherToPpass == 1" style="color: #2E8B57;">是</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" align="center" width="120" label="操作">
        <template scope="{ row }">
         <el-button type="text" @click="adopt(row)" :disabled="row.approvalStatus === 1 ? true : false">通过</el-button>
         <el-button type="text" @click="refuse(row)" :disabled="row.approvalStatus === 1 ? true : false">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination :current-page="currentPage"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="count"
                     @size-change="sizeChange"
                     @current-change="currentChange">
      </el-pagination>
    </section>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
     >
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="reviewMessage"
        maxlength="30"
        show-word-limit
      >
      </el-input>
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="updateIncidentReview">确 定</el-button>
    </el-dialog>
  </section>
</template>
<script>
import { reviewList, incidentReview } from '@/api/eventEvolution'
export default {
  methods: {
    dateFormat (date) {
	    date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min) + ':' +
    　　  (sec <10 ? '0' + sec : sec)
　　  return newTime
    },
    updateIncidentReview () {
      this.viewIncidentReview(this.reviewMessageDialog.id, 0, this.reviewMessage)
      this.dialogVisible = false
    },
    async viewIncidentReview (id, whetherToPpass, reviewMessage) {
      try {
        const res = await incidentReview({ id: id, whetherToPpass: whetherToPpass, reviewMessage: reviewMessage })
        if (res.code === 0) {
          this.$message.success('操作成功')
          try {
            const res = await reviewList({ pageSize: this.limit, current: this.currentPage, del: 0 })
            this.tableData = res.data
            this.count = res.count
          } catch (err) {
            console.log(err)
          }
        } else {
          this.$message.error('操作失败，请重试')
        }
      } catch (err) {
        console.log(err)
      }
    },
    async viewReviewList () {
      this.loading = true
      try {
        const res = await reviewList({ pageSize: this.limit, current: this.currentPage, del: 0 })
        this.tableData = res.data
        this.count = res.count
        this.loading = false
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    adopt (val) {
      console.log(val)
      this.viewIncidentReview(val.id, 1, this.reviewMessage)
    },
    refuse (val) {
      this.dialogVisible = true
      this.reviewMessageDialog = val
      // val.state = 0
      // // 弹窗 填写拒绝的理由
      // this.$message('该事件未通过审核')
    },
    async sizeChange (i) {
      this.currentPage = 1
      this.limit = i
      try {
        const res = await reviewList({ pageSize: this.limit, current: this.currentPage, del: 0 })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    async currentChange (i) {
      this.currentPage = i
      try {
        const res = await reviewList({ pageSize: this.limit, current: this.currentPage, del: 0 })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted () {
    this.viewReviewList()
  },
  data () {
    return {
      reviewMessageDialog: { },
      dialogVisible: false,
      loading: false,
      tableData: [],
      count: 0,
      limit: 10,
      currentPage: 1,
      reviewMessage: ''
    }
  }
}
</script>

<style lang="scss">
  .audit-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    border-radius: 2px;
    padding: 40px 30px;
    position: relative;

    .el-table {
      margin-bottom: 50px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;

      .count {
        line-height: 32px;
      }
    }
  }
</style>
